// hbsfy compiled Handlebars template
var HandlebarsCompiler = require(116);
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.basket_item, '			', 'basket_item', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<ul id=\"xmlBasket\">\n		<li class=\"root\">\n			<a href=\"#\">Kurv</a>\n		</li>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.items : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "		<li class=\"basket-bottom\">\n			<div class=\"ShippingMethodFee\">\n				<span>Fragt- og betalingsgebyr</span>\n				<span>"
    + escapeExpression(((helpers.calculateFreight || (depth0 && depth0.calculateFreight) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.delivery : stack1), ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.payment : stack1), {"name":"calculateFreight","hash":{},"data":data})))
    + "</span>\n			</div>\n			<div class=\"BasketTotalPrice\">\n				<span>Total</span>\n				<span>"
    + escapeExpression(((helpers.calculateSum || (depth0 && depth0.calculateSum) || helperMissing).call(depth0, (depth0 != null ? depth0.items : depth0), {"name":"calculateSum","hash":{},"data":data})))
    + "</span>\n			</div>\n		<div class=\"OrderButton\">\n			<a href=\"/shop/order1.html\">Bestil</a>\n		</div>\n		<div class=\"mini-terms-sidebar\">\n            <p class=\"sidebar\">Din bestilling er f&oslash;rst bindende, når vi har bekræftet din ordre.</p>\n        </div>\n	</li>\n</ul>";
},"usePartial":true,"useData":true});

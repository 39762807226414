// hbsfy compiled Handlebars template
var HandlebarsCompiler = require(116);
module.exports = HandlebarsCompiler.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<li class=\"basket-product\">\n	<div class=\"basket-left\">\n		<a href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\">\n			<img class=\"ProductImage_ShowBasket\" align=\"left\" src=\""
    + escapeExpression(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"image","hash":{},"data":data}) : helper)))
    + "\" border=\"0\">\n		</a>\n		<div class=\"ProductNumber\">"
    + escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"number","hash":{},"data":data}) : helper)))
    + "</div>\n	</div>\n	<div class=\"basket-right\">\n		<div class=\"ProductName\">"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\n		<div>\n			<div class=\"amount-container\">\n				<div class=\"DecButton\">\n					<a href=\"/shop/basket.asp?caller=showbasket&amp;dec=1&amp;keyid="
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n						<div class=\"button-container\">\n							<div class=\"amount-button\"></div>\n						</div>\n					</a>\n				</div>\n				<div class=\"Amount\">"
    + escapeExpression(((helper = (helper = helpers.quantity || (depth0 != null ? depth0.quantity : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"quantity","hash":{},"data":data}) : helper)))
    + "</div>\n				<div class=\"IncButton\">\n					<a href=\"/shop/basket.asp?caller=showbasket&amp;inc=1&amp;keyid="
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n                        <div class=\"button-container\">\n							<div class=\"amount-button\"></div>\n						</div>\n					</a>\n				</div>\n				<div class=\"RemButton\">\n					<a href=\"/shop/basket.asp?caller=showbasket&amp;remove=1&amp;keyid="
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n                    	<div class=\"button-container\">\n							<div class=\"amount-button\"></div>\n						</div>\n					</a>\n				</div>\n			</div>\n			<div class=\"ProductTotalPrice\">"
    + escapeExpression(((helpers.calculatePrice || (depth0 && depth0.calculatePrice) || helperMissing).call(depth0, (depth0 != null ? depth0.quantity : depth0), (depth0 != null ? depth0.price : depth0), {"name":"calculatePrice","hash":{},"data":data})))
    + "</div>\n		</div>\n	</div>\n</li>";
},"useData":true});

'use strict';

var Handlebars = require(116);
var currencyParser = require(64);

Handlebars.registerHelper('calculateFreight', function(delivery, payment) {
    if (delivery) {
        var totalPrice = delivery + payment;
        return currencyParser(totalPrice);
    }
    return currencyParser(0);
});

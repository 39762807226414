'use strict';

// Loading jQuery
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

// Loading Handlebars
var Handlebars = require('hbsfy/runtime');

// Registering partials
Handlebars.registerPartial('basket_item', require('templates/basket_item'));

// Loading handlebars helpers
require('modules/handlebars/helpers/calculate.freight');
require('modules/handlebars/helpers/calculate.price');
require('modules/handlebars/helpers/calculate.sum');
require('modules/handlebars/helpers/index-from-one');

// Loading model and template
var basket = require('modules/models/basket');
var template = require('templates/basket');


// Updates the view
function _updateView(data) {
    $('.right-off-canvas.basket').html(template(data));
    $('.BasketTotalProducts').text(data.totalItems);
}

// Calculates the index of the item clicked
function _getItemIndex(event) {
    event.preventDefault();
    event.stopPropagation();
    var target = $(event.target);
    var product = target.closest('.basket-product');
    return product.index() - 1;
}

// Registeres event listeners
function _registerEventListeners() {
    $(document).on('click', '.amount-container .IncButton a', function(event) {
        var index = _getItemIndex(event);
        basket.increase(index);
    });
    $(document).on('click', '.amount-container .DecButton a', function(event) {
        var index = _getItemIndex(event);
        basket.decrease(index);
    });
    $(document).on('click', '.amount-container .RemButton a', function(event) {
        var index = _getItemIndex(event);
        basket.remove(index);
    });
}

// Initializes the basket controller
function init() {
    basket.addEventListener(_updateView);
    _registerEventListeners();
}

module.exports = {
    init: init
};

require=(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({9:[function(require,module,exports){
'use strict';

// mobile basket - do not load on large screens or checkout
var Basket = require(31);

$(function() {
    Basket.init();
});

},{"31":31}],31:[function(require,module,exports){
(function (global){
'use strict';

// Loading jQuery
var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

// Loading Handlebars
var Handlebars = require(116);

// Registering partials
Handlebars.registerPartial('basket_item', require(84));

// Loading handlebars helpers
require(37);
require(38);
require(39);
require(46);

// Loading model and template
var basket = require(50);
var template = require(83);


// Updates the view
function _updateView(data) {
    $('.right-off-canvas.basket').html(template(data));
    $('.BasketTotalProducts').text(data.totalItems);
}

// Calculates the index of the item clicked
function _getItemIndex(event) {
    event.preventDefault();
    event.stopPropagation();
    var target = $(event.target);
    var product = target.closest('.basket-product');
    return product.index() - 1;
}

// Registeres event listeners
function _registerEventListeners() {
    $(document).on('click', '.amount-container .IncButton a', function(event) {
        var index = _getItemIndex(event);
        basket.increase(index);
    });
    $(document).on('click', '.amount-container .DecButton a', function(event) {
        var index = _getItemIndex(event);
        basket.decrease(index);
    });
    $(document).on('click', '.amount-container .RemButton a', function(event) {
        var index = _getItemIndex(event);
        basket.remove(index);
    });
}

// Initializes the basket controller
function init() {
    basket.addEventListener(_updateView);
    _registerEventListeners();
}

module.exports = {
    init: init
};

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"116":116,"37":37,"38":38,"39":39,"46":46,"50":50,"83":83,"84":84}],84:[function(require,module,exports){
// hbsfy compiled Handlebars template
var HandlebarsCompiler = require(116);
module.exports = HandlebarsCompiler.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<li class=\"basket-product\">\n	<div class=\"basket-left\">\n		<a href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\">\n			<img class=\"ProductImage_ShowBasket\" align=\"left\" src=\""
    + escapeExpression(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"image","hash":{},"data":data}) : helper)))
    + "\" border=\"0\">\n		</a>\n		<div class=\"ProductNumber\">"
    + escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"number","hash":{},"data":data}) : helper)))
    + "</div>\n	</div>\n	<div class=\"basket-right\">\n		<div class=\"ProductName\">"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\n		<div>\n			<div class=\"amount-container\">\n				<div class=\"DecButton\">\n					<a href=\"/shop/basket.asp?caller=showbasket&amp;dec=1&amp;keyid="
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n						<div class=\"button-container\">\n							<div class=\"amount-button\"></div>\n						</div>\n					</a>\n				</div>\n				<div class=\"Amount\">"
    + escapeExpression(((helper = (helper = helpers.quantity || (depth0 != null ? depth0.quantity : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"quantity","hash":{},"data":data}) : helper)))
    + "</div>\n				<div class=\"IncButton\">\n					<a href=\"/shop/basket.asp?caller=showbasket&amp;inc=1&amp;keyid="
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n                        <div class=\"button-container\">\n							<div class=\"amount-button\"></div>\n						</div>\n					</a>\n				</div>\n				<div class=\"RemButton\">\n					<a href=\"/shop/basket.asp?caller=showbasket&amp;remove=1&amp;keyid="
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n                    	<div class=\"button-container\">\n							<div class=\"amount-button\"></div>\n						</div>\n					</a>\n				</div>\n			</div>\n			<div class=\"ProductTotalPrice\">"
    + escapeExpression(((helpers.calculatePrice || (depth0 && depth0.calculatePrice) || helperMissing).call(depth0, (depth0 != null ? depth0.quantity : depth0), (depth0 != null ? depth0.price : depth0), {"name":"calculatePrice","hash":{},"data":data})))
    + "</div>\n		</div>\n	</div>\n</li>";
},"useData":true});

},{"116":116}],83:[function(require,module,exports){
// hbsfy compiled Handlebars template
var HandlebarsCompiler = require(116);
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.basket_item, '			', 'basket_item', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<ul id=\"xmlBasket\">\n		<li class=\"root\">\n			<a href=\"#\">Kurv</a>\n		</li>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.items : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "		<li class=\"basket-bottom\">\n			<div class=\"ShippingMethodFee\">\n				<span>Fragt- og betalingsgebyr</span>\n				<span>"
    + escapeExpression(((helpers.calculateFreight || (depth0 && depth0.calculateFreight) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.delivery : stack1), ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.payment : stack1), {"name":"calculateFreight","hash":{},"data":data})))
    + "</span>\n			</div>\n			<div class=\"BasketTotalPrice\">\n				<span>Total</span>\n				<span>"
    + escapeExpression(((helpers.calculateSum || (depth0 && depth0.calculateSum) || helperMissing).call(depth0, (depth0 != null ? depth0.items : depth0), {"name":"calculateSum","hash":{},"data":data})))
    + "</span>\n			</div>\n		<div class=\"OrderButton\">\n			<a href=\"/shop/order1.html\">Bestil</a>\n		</div>\n		<div class=\"mini-terms-sidebar\">\n            <p class=\"sidebar\">Din bestilling er f&oslash;rst bindende, når vi har bekræftet din ordre.</p>\n        </div>\n	</li>\n</ul>";
},"usePartial":true,"useData":true});

},{"116":116}],46:[function(require,module,exports){
'use strict';

var Handlebars = require(116);

Handlebars.registerHelper('indexFromOne', function(index) {
    return index + 1;
});

},{"116":116}],38:[function(require,module,exports){
'use strict';

var Handlebars = require(116);
var currencyParser = require(64);

Handlebars.registerHelper('calculatePrice', function(amount, unitPrice) {
    var totalPrice = amount * unitPrice;
    return currencyParser(totalPrice);
});

},{"116":116,"64":64}],37:[function(require,module,exports){
'use strict';

var Handlebars = require(116);
var currencyParser = require(64);

Handlebars.registerHelper('calculateFreight', function(delivery, payment) {
    if (delivery) {
        var totalPrice = delivery + payment;
        return currencyParser(totalPrice);
    }
    return currencyParser(0);
});

},{"116":116,"64":64}]},{},[9])

